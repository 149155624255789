import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import _ from 'lodash'

const TagsPage = ({ data }) => {
  const tags = data.allMarkdownRemark.group

  return (
    <Layout>
      <Seo title="All tags" />
      <h2 className="text-4xl font-bold mb-6 border-b pb-4 text-blue-800">All Tags</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {tags.map(tag => (
          <div key={tag.fieldValue} className="bg-white shadow-md rounded-lg overflow-hidden border-2 border-blue-200 transition-all duration-300 hover:shadow-lg hover:border-blue-400">
            <div className="p-6">
              <Link to={`/tags/${_.kebabCase(tag.fieldValue.toLowerCase())}/`} className="text-blue-700 hover:text-blue-900">
                <h3 className="text-2xl font-bold mb-2">{tag.fieldValue}</h3>
              </Link>
              <p className="text-gray-700 mb-4">{tag.totalCount} post{tag.totalCount !== 1 ? 's' : ''}</p>
              <Link to={`/tags/${_.kebabCase(tag.fieldValue.toLowerCase())}/`} className="inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors duration-300">
                View Posts
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default TagsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
